// import WebpackerReact from 'webpacker-react'
// import assign from 'lodash/assign'

// ======== HEAD
// import Signup from "./components/signup"
// import Admin from "./components/admin"

// WebpackerReact.setup(assign({}, Admin, Signup))
// ==========

// import Admin from "../components/admin"

// WebpackerReact.setup(assign({}, Admin))

import 'bugsnag.jsx'
var ReactRailsUJS = require('react_ujs')
var componentRequireContext = require.context('components/wenigermiete', true)
ReactRailsUJS.useContext(componentRequireContext)
import '@hotwired/turbo-rails'
// >>>>>>> updated-rails-react-integration-gem-in-rails-react-out-of-the-box-way
