import { load } from 'react-cookies'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

function initializeBugsnag(apiKey) {
  Bugsnag.start({
    apiKey,
    plugins: [new BugsnagPluginReact()],
  })
  BugsnagPerformance.start({ apiKey })
}

function LoadBugsnag() {
  const bugsnagApiKey = load('bugsnag')

  if (bugsnagApiKey === undefined) {
    console.error('Bugsnag API key not found in cookies')
  } else {
    initializeBugsnag(bugsnagApiKey)
  }
}

LoadBugsnag()
export default LoadBugsnag
